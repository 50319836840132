export default () => {

  const menu_drop = document.querySelectorAll('.header-middle-mobile .js-open-submenu')
  const container_menu_drop = document.querySelectorAll('.header-middle-mobile .mobile-menu .sub-menu')

  menu_drop.forEach((item, index) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      container_menu_drop[index].classList.toggle('active')
    })
  })

}